import React from "react";

const HeaderMenuPrimary = () => {
  return (
    <div className="menu">
      <nav className="menu-primary">
        <ul className="nav">
          <li className="nav-item">
            <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
              Services
            </a>
          </li>

          <li className="nav-item">
            <a title="About Us" href={process.env.PUBLIC_URL + "/#about-us"}>
              About Us
            </a>
          </li>

          <li className="nav-item">
            <a
              title="Client Stories"
              href={process.env.PUBLIC_URL + "/#testimonials"}
            >
              Client Stories
            </a>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/news") ||
              window.location.pathname.includes("/news-single-page")
                ? "current-nav-item"
                : "")
            }
          >
            <a title="Blogs" href={process.env.PUBLIC_URL + "/news"}>
              Blogs
            </a>
          </li>

          <li className="nav-item">
            <a title="Contact Us" href={process.env.PUBLIC_URL + "/#contacts"}>
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderMenuPrimary;
