import React from "react";

const AboutsUs = () => {
  return (
    <div className="wrapper">
      <div className="title">
        <h6 className="text-primary text-uppercase">About Us</h6>
      </div>

      <div className="row gutter-width-md with-pb-md">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="description-2">
            <h3>Solutions for end-to-end digital transformation intiatives.</h3>
          </div>

          {/* <div className="signature spacer m-top-lg">
                        <div className="signature-img">
                            <img src="assets/img/demo/23_img.png" alt="Signature" />
                        </div>
                        <div className="signature-content">
                            <h5 className="signature-name">Tom Henders</h5>
                            <p className="signature-position">CEO of Company</p>
                        </div>
                    </div> */}
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="description-2">
            <p>
            Z3 originated as a handful of experienced technology consultants 
            specializing in DevOps and full-stack engineering. With the growing need for technical 
            expertise, Z3 has evolved into a wide array of practitioners with real, 
            large-scale production system experience. With the experience on hand, 
            we reduce the risk of unforeseen failure in complex projects and help clients achieve success. .{" "}
            </p>
          </div>

          <div className="description-2 spacer p-top-lg">
            <p>
            Our problem-solving mindset helps us take on complex projects 
            head-on, and our passion for high-quality software helps clients reach 
            their desired goals while building long-term partnerships with our clients.  
            </p>
          </div>
        </div>
      </div>

      <div className="icon-list">
        <div className="icon-list-items">
          <div className="icon-list-item">
            <p>
              <span className="d-flex">
                <i className="fas fa-check"></i>
                <span>Constant Improvement</span>
              </span>
            </p>
          </div>

          <div className="icon-list-item">
            <p>
              <span className="d-flex">
                <i className="fas fa-check"></i>
                <span>Commitment to Customers</span>
              </span>
            </p>
          </div>

          <div className="icon-list-item">
            <p>
              <span className="d-flex">
                <i className="fas fa-check"></i>
                <span>Best Quality You Can Get</span>
              </span>
            </p>
          </div>

         
                   
        </div>
      </div>
    </div>
  );
};

export default AboutsUs;
