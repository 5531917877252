import React from "react";

const HomeModalMenu = () => {
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li className="nav-item">
          <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
            Services
          </a>
        </li>

        <li className="nav-item">
          <a title="About Us" href={process.env.PUBLIC_URL + "/#about-us"}>
            About Us
          </a>
        </li>

        <li className="nav-item">
          <a title="Client Stories" href={process.env.PUBLIC_URL + "/#testimonials"}>
            Client Stories
          </a>
        </li>

        <li className="nav-item">
          <a title="News" href={process.env.PUBLIC_URL + "/#news"}>
            Blogs
          </a>
        </li>

        <li className="nav-item">
          <a title="Contacts" href={process.env.PUBLIC_URL + "/#contacts"}>
            Contact Us
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default HomeModalMenu;
