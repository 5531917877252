import React from 'react';

const ServicesHome = () => {
    return (
        <section id="services" className="block spacer p-bottom-xl-2">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-md service-items">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="service">
                           <div className="service-icon">
                              <i className="fas fa-cogs"></i>   
                           </div>

                            <h4 className="service-t-head">DevOps Enablement</h4>

                            <p className="service-description">We engage with clients who are in the various stages of the DevOps journey and help them scale technology, processes and large-scale cloud transformations. We have expertise in the areas of AWS, Azure, and GCP. </p>

                            {/* <div className="service-btn">
                                 <a title="Read more" className="btn btn-sm btn-link btn-icon-hover p-0 border-0 min-w-auto link-no-space text-uppercase" href={ process.env.PUBLIC_URL + "/service-inside" }>
                                    <i className="malex-icon-arrow-right i-large"></i>
                                    <span className="btn-text">Read more</span>
                                </a> 
                            </div> */}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="service">
                        <div className="service-icon">
                              <i className="fas fa-laptop-code"></i>
                        </div>

                            <h4 className="service-t-head">Full-stack Development</h4>

                            <p className="service-description">We work closely to understand product needs, design solutions that meet product goals, and use data to course-correct, and eventually scale. We cater to both Web and Mobile needs of our clients. </p>
                                 {/* <div className="service-btn">
                                <a title="Read more" className="btn btn-sm btn-link btn-icon-hover p-0 border-0 min-w-auto link-no-space text-uppercase" href={ process.env.PUBLIC_URL + "/service-inside" }>
                                    <i className="malex-icon-arrow-right i-large"></i>
                                    <span className="btn-text">Read more</span>
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="service">
                        <div className="service-icon">
                              <i className="fas fa-cloud-meatball"></i>
                        </div>
                            <h4 className="service-t-head">Managed Cloud Services</h4>

                            <p className="service-description">We provide expertise in managing Private and Public Clouds. Specific focus on Kubernetes environments and all three of the major Public Clouds (AWS, Azure, GCP).  </p>
                            {/* <div className="service-btn">
                                <a title="Read more" className="btn btn-sm btn-link btn-icon-hover p-0 border-0 min-w-auto link-no-space text-uppercase" href={ process.env.PUBLIC_URL + "/service-inside" }>
                                    <i className="malex-icon-arrow-right i-large"></i>
                                    <span className="btn-text">Read more</span>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesHome;