import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© Z3 Info Systems</p>
    </div>
  );
};

export default Copyright;
