import React from "react";

const PageTitleNewsSingle = () => {
  return (
    <div id="page-title" className="page-title-ml">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Blogs</h1>
        </div>
      </div>
    </div>
  );
};

export default PageTitleNewsSingle;
