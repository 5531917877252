import React from "react";

const PageTitleHome = () => {
  return (
    <section
      id="page-title"
      className="block with-img with-service-items"
      style={{
        backgroundImage: `url( ${require("../../assets/img/placeholder/bg.png")} )`,
      }}
    >
      <div className="wrapper d-flex flex-column justify-content-center">
        <div className="page-title-body page-title-body-space-left">
          <div className="title">
            <h1 className="large">
              Digital
              <br /> Transformation Partner
            </h1>
          </div>

          <div className="description spacer p-top-lg">
            <p>Cutting edge digital transformation services, delivered.</p>
          </div>
        </div>

        <div className="page-title-footer full-width">
          <div className="d-flex justify-content-between"></div>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleHome;
